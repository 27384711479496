import React from 'react';
import { WrapPageElementBrowserArgs } from 'gatsby';

import Layout from './src/components/Layout';
import CartProvider from './src/contexts/cartContext';
import ParamsProvider from './src/contexts/paramsContext';
import I18NextProvider, { PageContext } from './src/contexts/i18nContext';

export const wrapRootElement = ({ element }: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <CartProvider>
      <ParamsProvider>{element}</ParamsProvider>
    </CartProvider>
  );
};

export const wrapPageElement = ({ element, props }: any, localeProps: any) => (
  <I18NextProvider options={localeProps} {...props}>
    <Layout {...props}>{element}</Layout>
  </I18NextProvider>
);
