import styled from 'styled-components';
import React, { FC } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import { flexCenter, flexColumn, flexRow, media } from '../../styles/helpers';
import { DEFAULT_LANGUAGE } from '../../../languages';

const laptopStyles = media.laptop`
  ${flexRow};
  gap: 50px;
  a {
    font-size: 20px;
  }

  nav {
    margin-right: auto!important;
  }
`;

const Wrapper = styled.nav`
  ${flexColumn};
  ${flexCenter};
  gap: 15px;

  a {
    font-size: 18px;
    :hover {
      color: #1f1f1f;
    }
  }

  ${laptopStyles};
`;

const LINKS = [
  {
    name: 'SHOP_PAGE.title',
    route: '/shop',
  },
  {
    name: 'BLOG_PAGE.title',
    route: '/blog',
  },
  {
    name: 'CONTACT_PAGE.title',
    route: '/contact-us',
  },
  {
    name: 'REVIEWS_PAGE.title',
    route: '/reviews',
  },
  {
    name: 'FAQ',
    route: '/faq',
  },
];

const Navigation: FC = () => {
  const { t, i18n } = useI18next();

  const urlPrefix = i18n.language === DEFAULT_LANGUAGE ? '' : '/' + i18n.language;

  return (
    <Wrapper>
      {LINKS.map((l) => (
        <Link key={l.name} to={urlPrefix + l.route}>
          {t(l.name)}
        </Link>
      ))}
    </Wrapper>
  );
};

export default Navigation;
