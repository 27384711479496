import React, { FC } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import amex from '../../static/images/payment-processor/amex.svg';
import visa from '../../static/images/payment-processor/visa.svg';
import maestro from '../../static/images/payment-processor/maestro.svg';
import applePay from '../../static/images/payment-processor/applePay.svg';
import googlePay from '../../static/images/payment-processor/googlePay.svg';
import mastercard from '../../static/images/payment-processor/mastercard.svg';

import { List, ImagesContainer, Text } from './styles';

type TPaymentProcessors = {
  text?: string;
};

const PaymentProcessors: FC<TPaymentProcessors> = ({ text }) => {
  const { t } = useI18next();
  return (
    <List className="list">
      {text && <Text>{t(text)}</Text>}
      <ImagesContainer>
        <img src={amex} alt="amex" />
        <img src={visa} alt="visa" />
        <img src={applePay} alt="apple pay" />
        <img src={googlePay} alt="google pay" />
        <img src={maestro} alt="maestro" />
        <img src={mastercard} alt="mastercard" />
      </ImagesContainer>
    </List>
  );
};

export default PaymentProcessors;
