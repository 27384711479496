import styled, { css } from 'styled-components';

import { flexBetween, flexCenter, flexRow, media } from '../../styles/helpers';

export const LogoWrapper = styled.div`
  height: 41px;
  max-width: 51px;
`;

export const Phone = styled.a`
  display: block;
  font-size: 18px;
  font-weight: 600;
`;

export const BurgerOpenButton = styled.img`
  display: block;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: var(--max-content-width);
  ${flexRow};
  ${flexCenter};
  ${flexBetween};
  padding: 10px 15px;
`;

const laptopStyles = media.laptop`

  nav {
    display: flex;
  }

  ${LogoWrapper} {
    height: 60px;
    max-width: 70px;
  }

  ${Phone}, ${BurgerOpenButton} {
    display: none;
  }

  ${Content} {
    padding: 10px 20px;
  }
`;

export const Wrapper = styled.header`
  width: 100%;
  margin: 0 auto;
  top: 0;
  min-height: 65px;
  position: sticky;
  z-index: 100;
  background: #fff;
  transition: background 0.3s ease-in-out;

  &.shadow {
    background: #eee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  nav {
    display: none;
  }

  ${laptopStyles}
`;

const rightGroupLaptopStyles = media.laptop`
  display: flex;
`;

export const RightGroup = styled.div`
  ${flexRow};
  gap: 30px;
  display: none;
  ${rightGroupLaptopStyles};
`;
