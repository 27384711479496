import { useEffect, useState } from 'react';

const useScrollOut = (top: number, isEnabled = true) => {
  const [isScrolledOut, setScrolledOut] = useState(false);

  const onScroll = () => {
    setScrolledOut(window.scrollY >= top);
  };

  useEffect(() => {
    if (!isEnabled) return;
    onScroll();

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isEnabled]);

  return isScrolledOut;
};

export default useScrollOut;
