import { styled } from 'styled-components';

import { flexCenter, flexColumn } from '../../styles/helpers';

export const Arrow = styled.img`
  position: absolute;
  right: 10px;
  top: 17px;
  width: 8px;
  filter: brightness(0.1);

  &.isOpened {
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  ${flexColumn};
  top: 40px;
  left: 0;
  width: 100%;
  position: absolute;
  background: rgba(232, 232, 232, 1);
  border-radius: 0 0 20px 20px;
  box-shadow: -3px 9px 14px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  max-height: 142px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Label = styled.div`
  ${flexCenter};
  position: relative;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 20px;
  padding-right: 22px;
  background: rgba(232, 232, 232, 1);

  &.isOpened {
    border-radius: 20px 20px 0 0;
  }
`;

export const LinkWrapper = styled.div`
  width: 100%;
  height: 40px;
  cursor: pointer;
  &.selected {
    text-decoration: underline;
  }
  > a {
    ${flexCenter};
    width: inherit;
    height: inherit;
  }
`;
