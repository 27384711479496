import styled from 'styled-components';
import { flexBetween, flexCenter, flexColumn, flexRow, media } from '../../styles/helpers';

export const Email = styled.a`
  color: #1f1f1f;
  font-size: 20px;
  text-decoration: none;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #b5b4b4;
`;

export const Logo = styled.img`
  height: 80px;
  aspect-ratio: 5/4;
`;

export const BottomLine = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;

  a:hover {
    text-decoration: underline;
  }
`;

export const Phone = styled.a`
  display: none;
  font-size: 40px;
  font-weight: 500;
`;

export const Box = styled.div`
  ${flexColumn};
  gap: 20px;
  padding: 0 20px;
  margin: 0 auto;
  max-width: var(--max-content-width);
`;

const laptopStyles = media.laptop`
  ${Phone} {
    display: block;
  }
	${Logo} {
		height: 85px;
	}
  ${Box} {
    width: 100%;
    ${flexRow};
    ${flexBetween};
    align-items: center;
  }
  ${BottomLine} {
    flex-direction: row;
  }
`;

export const Wrapper = styled.footer`
  ${flexColumn};
  ${flexCenter};
  gap: 20px;
  padding: 50px 0 30px;
  border-radius: 40px 40px 0px 0px;
  background: rgba(40, 34, 22, 0.22);

  a {
    display: flex;
    justify-content: center;
  }

  ${laptopStyles};
`;
