import { styled, css } from 'styled-components';

import { flexCenter, media } from '../../styles/helpers';

type CartProps = { className: string };

export const Counter = styled.div`
  position: absolute;
  background: #e33635;
  border-radius: 50%;
  padding: 2px 4px 0 4px;
  height: 22px;
  min-width: 22px;
  right: -20px;
  top: -34px;
  line-height: 14px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  ${flexCenter};
`;

export const CounterWrapper = styled.div`
  position: relative;
`;

const laptopStyles = media.laptop`
  top: 110px;
  right: 40px;
  height: 80px;
  width: 80px;

  img {
    width: 40px;
  }

  ${Counter} {
    top: -43px;
    right: -23px;
    height: 30px;
    min-width: 30px;
    line-height: 18px;
    font-size: 18px;
  }

  &.rtl {
    right: unset;
    left: 40px;

    ${Counter} {
      right: unset;
      left: -23px;
    }
  }
`;

export const CartButton = styled.div<CartProps>`
  position: fixed;
  z-index: 99;
  top: 73px;
  right: 14px;
  width: 60px;
  height: 60px;
  ${flexCenter};
  border-radius: 50%;
  background: #eaeaeabd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  img {
    width: 30px;
    aspect-ratio: 1/1;
  }

  &.rtl {
    right: unset;
    left: 14px;

    ${Counter} {
      right: unset;
      left: -23px;
    }
  }

  ${laptopStyles};
`;
