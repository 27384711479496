import { useLocation } from '@reach/router';
import { useI18next, Link } from 'gatsby-plugin-react-i18next';
import React, { FC, useEffect, useRef, useState } from 'react';

import arrowDown from '../../static/images/arrow_down.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../../languages';

import { Arrow, Wrapper, Menu, Label, LinkWrapper } from './styles';

const LanguageSelector: FC = () => {
  const { i18n } = useI18next();
  const location = useLocation();

  const [isOpened, seIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => seIsOpened(false));

  const label = (i18n.resolvedLanguage || DEFAULT_LANGUAGE).toUpperCase();
  const links = SUPPORTED_LANGUAGES.slice(1).filter((l) => !l.alias);

  const toggle = () => seIsOpened((v) => !v);

  const getPathWithoutLang = () => '/' + location.pathname.split('/').slice(2).join('/');
  const toPage = i18n.resolvedLanguage === DEFAULT_LANGUAGE ? location.pathname : getPathWithoutLang();

  useEffect(() => {
    if (!isOpened) return;

    const selectedLangElement = ref.current?.querySelector('[data-selected="true"]');

    setTimeout(() => selectedLangElement?.scrollIntoView({ inline: 'center', block: 'center' }), 0);
  }, [isOpened]);

  return (
    <Wrapper ref={ref} onScroll={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
      <Label className={isOpened ? 'isOpened' : ''} onClick={toggle}>
        {label}
        <Arrow className={isOpened ? 'isOpened' : ''} src={arrowDown} alt="arrow" />
      </Label>
      {isOpened && (
        <Menu>
          {links.map(({ code }, index) => (
            <LinkWrapper
              key={index}
              className={i18n.resolvedLanguage === code ? 'selected' : ''}
              data-selected={(i18n.resolvedLanguage === code).toString()}
              onClick={toggle}
            >
              <Link to={toPage} language={code}>
                {code.toLocaleUpperCase()}
              </Link>
            </LinkWrapper>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

export default LanguageSelector;
