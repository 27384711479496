import React, { FC } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import Logo from '../Logo';
import SocialList from '../SocialList';
import Navigation from '../Navigation';
import PaymentProcessors from '../PaymentProcessors';

import { Email, Divider, BottomLine, Phone, Box, Wrapper } from './styles';

const Footer: FC = () => (
  <Wrapper>
    <Box>
      <Email href="mailto: forestshopcarpatian@gmail.com">forestshopcarpatian@gmail.com</Email>
      <Phone href="https://wa.me/380975043893" target="_blank" rel="nofollow">
        +380975043893
      </Phone>
      <SocialList />
    </Box>

    <Divider />
    <Box>
      <Logo />
      <Navigation />
    </Box>
    <BottomLine>
      <p>
        © Amanita store 2019-{new Date().getFullYear()} <Link to="/policy">policy</Link>
      </p>
      <PaymentProcessors text="FOOTER.securePayment" />
    </BottomLine>
  </Wrapper>
);

export default Footer;
