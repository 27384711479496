export const getParams = <T extends string>(search: string) =>
  search
    .replace('?', '')
    .split('&')
    .reduce((acc, item) => {
      const [key, value] = item.split('=');

      if (!key) return acc;

      return {
        ...acc,
        [key]: value,
      };
    }, {} as Record<T, string>);

export const stringifyParams = (params: Record<string, string>) =>
  Object.keys(params).reduce(
    (acc, key) => `${acc} 
   ${key}: ${params[key]}`,
    ''
  );
