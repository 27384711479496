import { useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useState } from 'react';

export const RTL_LANGUAGES = ['he', 'ar'];

export type RTLProps = { isRTL: boolean };

const useHtmlDirection = (currentLang?: string) => {
  const { language } = useI18next();

  const lang = currentLang || language;
  const isRTLLang = RTL_LANGUAGES.includes(lang);

  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    setIsRTL(isRTLLang);
  }, [isRTLLang]);

  return { isRTL };
};

export default useHtmlDirection;
