import React, { FC, useContext } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import cartImage from '../../static/images/cart-red.svg';
import useMounted from '../../hooks/useMounted';
import { CartContext } from '../../contexts/cartContext';
import useHtmlDirection from '../../hooks/useHtmlDirection';

import { CartButton, CounterWrapper, Counter } from './styles';

type TShoppingCartLink = {
  currentLang: string;
};

const ShoppingCartLink: FC<TShoppingCartLink> = ({ currentLang }) => {
  const isMounted = useMounted();
  const { totalItems } = useContext(CartContext);
  const { isRTL } = useHtmlDirection(currentLang);

  if (!isMounted || totalItems === 0) return null;

  return (
    <Link to="/checkout">
      <CartButton className={isRTL ? 'rtl' : ''}>
        <img src={cartImage} alt="cart" />
        <CounterWrapper>
          <Counter>{totalItems}</Counter>
        </CounterWrapper>
      </CartButton>
    </Link>
  );
};

export default ShoppingCartLink;
