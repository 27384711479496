import styled from 'styled-components';
import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import { flexRow } from '../../styles/helpers';

const Wrapper = styled.nav`
  ${flexRow};
  gap: 20px;
`;

type Social = {
  alt: string;
  href: string;
  rel?: string;
};

const socials: Social[] = [
  {
    alt: 'instagram',
    rel: 'nofollow',
    href: 'https://www.instagram.com/amanita_muscaria_shop/',
  },
  {
    alt: 'tiktok',
    rel: 'nofollow',
    href: 'https://www.tiktok.com/@amanita.muscaria.store',
  },
  {
    alt: 'telegram',
    rel: 'nofollow',
    href: 'https://telegram.me/vikror777',
  },
  {
    alt: 'whatsApp',
    rel: 'nofollow',
    href: 'https://wa.me/380975043893',
  },
  {
    alt: 'gmail',
    href: 'mailto:forestshopcarpatian@gmail.com',
  },
];

const SocialLink = styled.a<Pick<Social, 'href'>>`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
`;

const SocialList: FC = () => {
  const data = useStaticQuery<any>(graphql`
    query SocialList {
      icons: allFile(filter: { absolutePath: { regex: "/social/" } }, sort: { relativePath: ASC }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      {socials.map((s, i) => (
        <SocialLink key={s.href} href={s.href} target="_blank" rel={s.rel}>
          <GatsbyImage image={getImage(data.icons.edges[i].node as ImageDataLike)!} alt={s.alt} />
        </SocialLink>
      ))}
    </Wrapper>
  );
};

export default SocialList;
