import styled, { css } from 'styled-components';

import { flexColumn } from '../../../styles/helpers';

const drawerAnimations = css`
  @keyframes slideInDrawer {
    0% {
      left: 100%;
      right: -100%;
    }
    100% {
      left: 0%;
      right: 0%;
    }
  }

  @keyframes slideIOutDrawer {
    0% {
      left: 0%;
      right: 0%;
    }
    100% {
      left: 100%;
      right: -100%;
    }
  }

  &.open-animation {
    animation: 300ms ease-in-out forwards slideInDrawer;
  }
  &.close-animation {
    animation: 300ms ease-in-out forwards slideIOutDrawer;
  }
`;

export const BurgerCloseButton = styled.img``;

export const Divider = styled.div`
  width: calc(100% - 30px);
  height: 2px;
  padding: 0 15px;
  background: #f2f2f2;
`;

export const Email = styled.a`
  font-size: 20px;
  font-weight: 400;
`;

export const Wrapper = styled.div`
  gap: 25px;
  ${flexColumn};
  ${drawerAnimations};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  position: fixed;
  background: #fff;
  align-items: center;

  nav:first-child,
  nav:nth-child(5) {
    display: flex;
  }

  header + nav {
    margin-top: 25px;
  }
`;
