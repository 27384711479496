import React, { createContext, useState, useEffect, FC, PropsWithChildren } from 'react';
import { getParams } from '../helpers/parseParams';

export const ParamsContext = createContext({});

const PARAMS_KEY = 'site-params';

const ParamsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pageParams, setPageParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const urlParams = getParams(window.location.search.replace('payed=true', ''));
    const savedParams: Record<string, string> = JSON.parse(localStorage.getItem(PARAMS_KEY) || '{}');

    const newParams = { ...savedParams, ...urlParams };

    setPageParams(newParams);
    localStorage.setItem(PARAMS_KEY, JSON.stringify(newParams));
  }, []);

  return <ParamsContext.Provider value={pageParams}>{children}</ParamsContext.Provider>;
};

export default ParamsProvider;
