export type LangCode =
  | 'en'
  | 'de'
  | 'es'
  | 'it'
  | 'pt'
  | 'br'
  | 'ar'
  | 'ca'
  | 'he'
  | 'zh'
  | 'ja'
  | 'fr'
  | 'id'
  | 'uk'
  | 'ru'
  | 'ua'
  | 'hu'
  | 'tr'
  | 'sv'
  | 'ro'
  | 'nl'
  | 'fi'
  | 'et'
  | 'el'
  | 'pl'
  | 'da'
  | 'cs'
  | 'lt'
  | 'lv'
  | 'et'
  | 'fi'
  | 'no'
  | 'sv'
  | 'x-default';

export type HrefLang =
  | 'de'
  | 'es'
  | 'it'
  | 'pt'
  | 'he'
  | 'et'
  | 'pt'
  | 'zh'
  | 'ja'
  | 'ar'
  | 'fr'
  | 'id'
  | 'en'
  | 'uk'
  | 'ru'
  | 'hu'
  | 'tr'
  | 'sv'
  | 'pl'
  | 'ro'
  | 'nl'
  | 'fi'
  | 'et'
  | 'el'
  | 'da'
  | 'cs'
  | 'lt'
  | 'lv'
  | 'fi'
  | 'no'
  | 'sw'
  | 'x-default';

export type Lang = {
  code: LangCode;
  hreflang: HrefLang;
  label?: string;
  alias?: LangCode;
};

export const DEFAULT_LANGUAGE = 'en';

export const SUPPORTED_LANGUAGES: Lang[] = [
  { code: 'x-default', hreflang: 'x-default' },
  { code: 'en', hreflang: 'en', label: 'English' },
  { code: 'de', hreflang: 'de', label: 'Deutsch' },
  { code: 'ua', hreflang: 'uk', label: 'Українська' },
  { code: 'he', hreflang: 'he', label: 'Hebrew' },
  { code: 'es', hreflang: 'es', label: 'Español' },
  { code: 'fr', hreflang: 'fr', label: 'Français' },
  { code: 'tr', hreflang: 'tr', label: 'Türk' },
  { code: 'pl', hreflang: 'pl', label: 'Polskie' },
  { code: 'nl', hreflang: 'nl', label: 'Nederlands' },
  { code: 'ja', hreflang: 'ja', label: '日本語' },
  { code: 'zh', hreflang: 'zh', label: '中文 (简体)' },
  { code: 'da', hreflang: 'da', label: 'Dansk' },
  { code: 'pt', hreflang: 'pt', label: 'Português' },
  { code: 'it', hreflang: 'it', label: 'Italiano' },
  { code: 'hu', hreflang: 'hu', label: 'Magyarország' },
  { code: 'cs', hreflang: 'cs', label: 'Čeština' },
  { code: 'et', hreflang: 'et', label: 'Estonia' },
  { code: 'lt', hreflang: 'lt', label: 'Lietuvių' },
  { code: 'lv', hreflang: 'lv', label: 'Latviski' },
  { code: 'fi', hreflang: 'fi', label: 'Finnish' },
  { code: 'sv', hreflang: 'sv', label: 'Swedish' },
  { code: 'no', hreflang: 'no', label: 'Norwegian' },
  { code: 'ar', hreflang: 'ar', label: 'Arabic' },
];

export const LANGUAGE_CODES = SUPPORTED_LANGUAGES.slice(0)
  .filter(({ code }) => code !== 'x-default')
  .map((l) => l.code);
