import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import Logo from '../Logo';
import menu from '../../static/images/open_burger_menu.svg';
import SocialList from '../SocialList';
import Navigation from '../Navigation';
import useScrollOut from '../../hooks/useScrollOut';
import LanguageSelector from '../LanguageSelector';

import Drawer from './Drawer';
import { Wrapper, LogoWrapper, Phone, BurgerOpenButton, Content, RightGroup } from './styles';

const Header: FC<PropsWithChildren> = ({ children }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [canCloseDrawer, setCanCloseDrawer] = useState(false);

  const isInsideDrawer = !!children;

  const isScrolledOut = useScrollOut(10, !isInsideDrawer);

  useEffect(() => {
    if (isOpenDrawer) return;
    setCanCloseDrawer(false);
  }, [isOpenDrawer]);

  const handelCloseDrawer = () => {
    setCanCloseDrawer(true);
    setTimeout(() => {
      setIsOpenDrawer(false);
    }, 500);
  };

  return (
    <Wrapper className={isScrolledOut ? 'shadow' : ''}>
      <Content>
        <LogoWrapper>
          <Logo showLogoSchema />
        </LogoWrapper>
        <Navigation />
        <Phone href="https://wa.me/380975043893" target="_blank">
          +380975043893
        </Phone>
        <RightGroup>
          <SocialList />
          <LanguageSelector />
        </RightGroup>
        {children || <BurgerOpenButton src={menu} alt="open menu" onClick={() => setIsOpenDrawer(true)} />}
        {isOpenDrawer && <Drawer canOpen={isOpenDrawer} canClose={canCloseDrawer} onClose={handelCloseDrawer} />}
      </Content>
    </Wrapper>
  );
};

export default Header;
