import React, { createContext, useEffect, useState, FC, PropsWithChildren } from 'react';

import isMobile from '../helpers/isMobile';
import useMounted from '../hooks/useMounted';

const CART_KEY = 'amanita-cart';

export type CartItem = {
  name: string;
  slug: string;
  units: string;
  price: string;
  quantity: number;
};

type CartActions = 'add' | 'remove' | 'remove-all';

type CartContextProps = {
  cart: CartItem[];
  totalPrice: number;
  totalItems: number;
  shopProductCount: number;
  handleShowMore(): void;
  setCartData(item: CartItem, action: CartActions): void;
};

export const CartContext = createContext({} as CartContextProps);

const CartProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useMounted();
  const [cart, updateCart] = useState<CartItem[]>([]);

  const shopProductsStep = !isMounted ? 5 : isMobile() ? 5 : 9;
  const [shopProductCount, setShopProductCount] = useState(shopProductsStep);

  const handleShowMore = () => {
    setShopProductCount((prevCount) => prevCount + shopProductsStep);
  };

  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);

  const totalPrice = cart.reduce((acc, item) => acc + item.quantity * +item.price, 0);

  const getCartData = () => {
    const rawData = localStorage.getItem(CART_KEY);
    const parsedData = rawData ? JSON.parse(rawData) : [];

    return parsedData;
  };

  const setCartData = (newItem: CartItem, action: CartActions) => {
    const rawData = localStorage.getItem(CART_KEY);
    const parsedData: CartItem[] = rawData ? JSON.parse(rawData) : [];

    const cartProductIndex = parsedData.findIndex((item) => item.price === newItem.price && item.name === newItem.name);

    if (action === 'add') {
      if (cartProductIndex !== -1) parsedData[cartProductIndex].quantity += 1;
      else parsedData.push(newItem);
    } else if (action === 'remove') {
      if (parsedData[cartProductIndex].quantity <= 1) {
        if (confirm(`Do you want to remove it?`)) {
          parsedData.splice(cartProductIndex, 1);
        }
      } else parsedData[cartProductIndex].quantity -= 1;
    } else if (action === 'remove-all') {
      if (
        cartProductIndex !== -1 &&
        confirm(`Do you want to remove this, ${parsedData[cartProductIndex].quantity} items?`)
      )
        parsedData.splice(cartProductIndex, 1);
    }

    updateCart(parsedData);
    localStorage.setItem(CART_KEY, JSON.stringify(parsedData));
  };

  const saveCartOnChanges = () => {
    const newData = getCartData();
    newData && updateCart(newData);
  };

  useEffect(() => {
    const newData = getCartData();
    newData && updateCart(newData);

    window.addEventListener('storage', saveCartOnChanges);

    return () => window.removeEventListener('storage', saveCartOnChanges);
  }, []);

  return (
    <CartContext.Provider value={{ cart, totalItems, totalPrice, setCartData, shopProductCount, handleShowMore }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
