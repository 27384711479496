import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { WEBSITE_URL } from '../../../constants';

type ImageObjectSchemaProps = {
  imageSrc: string;
  id?: string;
  name?: string;
  path?: string;
  width?: number;
  height?: number;
  description?: string;
};

export const getImageObjectSchema = ({
  id,
  path,
  name,
  description,
  imageSrc,
  width = 1000,
  height = 1000,
}: ImageObjectSchemaProps) => ({
  '@type': 'ImageObject',
  '@id': id || `${WEBSITE_URL}${path}`,
  name,
  width,
  height,
  description,
  author: 'Amanita store',
  url: `${WEBSITE_URL}${imageSrc}`,
  contentUrl: `${WEBSITE_URL}${imageSrc}`,
  datePublished: '2024-06-02',
});

const ImageObjectSchema: FC<ImageObjectSchemaProps> = (props) => {
  const schema = getImageObjectSchema(props);

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify({ '@context': 'https://schema.org', ...schema })}</script>
    </Helmet>
  );
};

export default ImageObjectSchema;
