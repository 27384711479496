import styled from 'styled-components';

import { flexRow, media, flexColumn } from '../../styles/helpers';

const laptopStyles = media.laptop`
	${flexRow};
  gap: 20px;

`;

export const List = styled.div`
  ${flexColumn}
  gap: 5px;
  align-items: center;

  ${laptopStyles}
`;

export const ImagesContainer = styled.div`
  ${flexRow}
  margin: 0;
  padding: 0;

  img {
    padding: 5px;
  }
`;

export const Text = styled.p``;
