import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { PageProps } from 'gatsby';
import React, { FC, PropsWithChildren, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';
import { flexColumn } from '../../styles/helpers';
import { GlobalStyles } from '../../styles/GlobalStyles';
import ShoppingCartLink from '../ShoppingCartLink';
import { PageContext } from '../../contexts/i18nContext';

const Wrapper = styled.div`
  ${flexColumn};
`;

const Main = styled.main``;

declare global {
  interface Window {
    gtag(): void;
    dataLayer?: any[];
  }
}

type TLayout = PropsWithChildren & PageProps;

const Layout: FC<TLayout> = (props) => {
  const { children, pageResources } = props;

  const currentLang = (pageResources?.json.pageContext as PageContext)?.i18n.language;

  useEffect(() => {
    const timer = setTimeout(() => {
      ReactGA.initialize('G-EQMR7NCCHG', { gtagUrl: 'https://www.googletagmanager.com/gtag/js' });
    }, 3000);

    // setTimeout(() => {
    //   window.dataLayer = window.dataLayer || [];

    //   function gtag(...props: any) {
    //     window.dataLayer?.push(arguments);
    //   }

    //   // TODO check ids
    //   gtag('js', new Date());
    //   gtag('config', 'G-EQMR7NCCHG');
    // }, 3500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
      {!pageResources?.page.path?.includes('checkout') && <ShoppingCartLink currentLang={currentLang} />}
      <Footer />
    </Wrapper>
  );
};

export default Layout;
