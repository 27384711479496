import { Link } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import ImageObjectSchema from './SEO/schemas/imageObject';

type LogoProps = {
  showLogoSchema?: boolean;
};

const Logo: FC<LogoProps> = ({ showLogoSchema }) => {
  const data = useStaticQuery<Queries.HeaderSectionQuery>(graphql`
    query HeaderSection {
      logoImage: file(name: { eq: "logo" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, sizes: "(max-width: 600px) 50px, 500px", formats: [AUTO])
        }
      }
    }
  `);

  const imgSrc = data.logoImage && getImage(data.logoImage as ImageDataLike);

  if (!imgSrc) return null;

  return (
    <Link to="/">
      {showLogoSchema && (
        <ImageObjectSchema name="Amanita store logo" path="/logo" imageSrc={data.logoImage.publicURL!} />
      )}
      <GatsbyImage objectFit="cover" image={imgSrc} sizes="(max-width: 600px) 50px, 100px" alt="Amanita store" />
    </Link>
  );
};

export default Logo;
