import { createGlobalStyle } from 'styled-components';

import './fontFace.css';

export const GlobalStyles = createGlobalStyle`
	body {
		margin: 0;
	}

	p {
		margin: 0;
	}
	
	* { 
    color: #1f1f1f;
		font-family: 'Gilroy';
		box-sizing: border-box;
    text-decoration: none;
	}
	:root {
		--max-content-width: 1600px;
	}
`;
