import { NodeInput } from 'gatsby';
import { I18nextProvider } from 'react-i18next';
import React, { FC, PropsWithChildren } from 'react';
import i18next, { InitOptions, Resource, ResourceKey } from 'i18next';

import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../../languages';

export type PageOptions = {
  matchPath: string;
  getLanguageFromPath?: boolean;
  excludeLanguages?: string[];
  languages?: string[];
};

export type I18NextContext = {
  language: string;
  routed: boolean;
  languages: string[];
  defaultLanguage: string;
  originalPath: string;
  path: string;
  siteUrl?: string;
};

export type PageContext = {
  language: string;
  i18n: I18NextContext;
  path?: string;
};

export type PluginOptions = {
  languages: string[];
  defaultLanguage: string;
  generateDefaultLanguagePage: boolean;
  redirect: boolean;
  siteUrl?: string;
  i18nextOptions: InitOptions;
  pages: Array<PageOptions>;
  localeJsonSourceName?: string;
  localeJsonNodeName?: string;
  fallbackLanguage?: string;
  trailingSlash?: 'always' | 'never' | 'ignore';
  verbose?: boolean;
};

export interface LocaleNodeInput extends NodeInput {
  language: string;
  ns: string;
  data: string;
  fileAbsolutePath: string;
}

export interface LocaleNode extends LocaleNodeInput {
  parent: string;
  children: string[];
  internal: NodeInput['internal'] & {
    owner: string;
  };
}

export const I18nextContext = React.createContext<I18NextContext>({
  language: DEFAULT_LANGUAGE,
  languages: LANGUAGE_CODES,
  routed: false,
  defaultLanguage: DEFAULT_LANGUAGE,
  originalPath: '/',
  path: '/',
});

type Props = PropsWithChildren<{
  props: any;
  options: PluginOptions;
  data: any;
  pageContext: any;
}>;

const I18NextProvider: FC<Props> = ({ children, data, pageContext, options }) => {
  const localeJsonNodeName = 'locales';

  const { i18nextOptions } = options;
  const { routed, language, languages, originalPath, defaultLanguage, path } = pageContext?.i18n || {};

  const localeNodes: Array<{ node: LocaleNode }> = data?.[localeJsonNodeName]?.edges || [];

  if (languages.length > 1 && localeNodes.length === 0 && process.env.NODE_ENV === 'development') {
    console.error(`No translations were found in "${localeJsonNodeName}" key for "${originalPath}".
    You need to add a graphql query to every page like this:`);
  }

  const namespaces = localeNodes.map(({ node }) => node.ns);

  // We want to set default namespace to a page namespace if it exists
  // and use other namespaces as fallback
  // this way you dont need to specify namespaces in pages
  let defaultNS = 'translation';
  defaultNS = namespaces.find((ns) => ns !== defaultNS) || defaultNS;
  const fallbackNS = namespaces.filter((ns) => ns !== defaultNS);

  const resources: Resource = localeNodes.reduce<Resource>((res: Resource, { node }) => {
    const parsedData: ResourceKey = typeof node.data === 'object' ? node.data : JSON.parse(node.data);

    if (!(node.language in res)) res[node.language] = {};

    res[node.language][node.ns || defaultNS] = parsedData;

    return res;
  }, {});

  const i18n = i18next.createInstance();

  i18n.init({
    ...(i18nextOptions || {}),
    resources,
    lng: language,
    fallbackLng: defaultLanguage,
    defaultNS,
    fallbackNS,
    react: {
      useSuspense: false,
    },
  });

  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }

  const context = {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    path,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <I18nextContext.Provider value={context}>{children}</I18nextContext.Provider>
    </I18nextProvider>
  );
};

export default I18NextProvider;
