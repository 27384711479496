import React, { FC } from 'react';

import Header from '..';
import crossButton from '../../../static/images/close_burger_menu.svg';

import { Wrapper, Divider, Email, BurgerCloseButton } from './styles';
import SocialList from '../../SocialList';
import Navigation from '../../Navigation';
import LanguageSelector from '../../LanguageSelector';

type TDrawer = {
  canOpen: boolean;
  canClose: boolean;
  onClose(): void;
};

const Drawer: FC<TDrawer> = ({ canOpen, canClose, onClose }) => {
  return (
    <Wrapper className={`${canClose ? 'close-animation ' : ''}${canOpen ? 'open-animation' : ''}`}>
      <Header>
        <BurgerCloseButton src={crossButton} alt="close menu" onClick={onClose} />
      </Header>

      <div onClick={onClose}>
        <Navigation />
      </div>

      <Divider />

      <Email href="mailto: forestshopcarpatian@gmail.com">forestshopcarpatian@gmail.com</Email>

      <SocialList />

      <Divider />

      <LanguageSelector />
    </Wrapper>
  );
};

export default Drawer;
