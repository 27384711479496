import { useEffect, RefObject } from 'react';

const useOutsideClick = <T extends Element>(ref: RefObject<T>, onClickOutside: (t: Element) => void) => {
  const onDocumentClick = ({ target }: Event) => {
    const isClickInside = ref.current && ref.current.contains(target as any);

    !isClickInside && onClickOutside(target as Element);
  };

  useEffect(() => {
    const event = document.ontouchstart !== null ? 'mousedown' : 'touchstart';
    document.body.addEventListener(event, onDocumentClick);

    return () => document.body.removeEventListener(event, onDocumentClick);
  }, [onClickOutside]);
};

export default useOutsideClick;
