import styled, { css } from 'styled-components';

type Device = 'desktop' | 'laptop' | 'mobile';

type MediaSizes = Record<Device, Function>;

const setMedia =
  (size: number) =>
  (strings: TemplateStringsArray, ...args: string[]) =>
    css`
      @media (min-width: ${size}px) {
        ${css(strings, ...args)};
      }
    `;

export const mediaSizes: Record<Device, number> = {
  desktop: 1200,
  laptop: 768,
  mobile: 576,
};

export const media: MediaSizes = Object.keys(mediaSizes).reduce<MediaSizes>((acc: MediaSizes, label: string) => {
  acc[label as Device] = setMedia(mediaSizes[label as Device]);
  return acc;
}, {} as MediaSizes);

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexBetween = css`
  display: flex;
  justify-content: space-between;
`;

const laptopTitle = media.laptop`
  font-size: 56px;
`;

export const Title = styled.h2`
  color: #1f1f1f;
  margin: 0;
  font-size: 30px;
  font-weight: 500;

  ${laptopTitle};
`;

const laptopDescription = media.laptop`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const Description = styled.p`
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  padding-left: 40px;

  ${laptopDescription};
`;

export const fadeInAnimation = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const SectionContainer = styled.div`
  margin: 0 auto;
  max-width: var(--max-content-width);
`;
