import { useEffect, useState } from 'react';

const useMounted = () => {
  const [isMounter, setIsMounter] = useState(false);

  useEffect(() => {
    setIsMounter(true);
  }, []);

  return isMounter;
};

export default useMounted;
